
    <template>
      <section class="content element-doc">
        <h2>Link 文字链接</h2>
<p>文字超链接</p>
<h3>基础用法</h3>
<p>基础的文字链接用法。</p>
<demo-block>
        
        <template #source><element-demo0 /></template>
        <template #highlight><pre v-pre><code class="html">&lt;div&gt;
  &lt;el-link href=&quot;https://element3-ui.com&quot; target=&quot;_blank&quot;&gt;默认链接&lt;/el-link&gt;
  &lt;el-link type=&quot;primary&quot;&gt;主要链接&lt;/el-link&gt;
  &lt;el-link type=&quot;success&quot;&gt;成功链接&lt;/el-link&gt;
  &lt;el-link type=&quot;warning&quot;&gt;警告链接&lt;/el-link&gt;
  &lt;el-link type=&quot;danger&quot;&gt;危险链接&lt;/el-link&gt;
  &lt;el-link type=&quot;info&quot;&gt;信息链接&lt;/el-link&gt;
&lt;/div&gt;
</code></pre></template></demo-block><h3>禁用状态</h3>
<p>文字链接不可用状态。</p>
<demo-block>
        
        <template #source><element-demo1 /></template>
        <template #highlight><pre v-pre><code class="html">&lt;div&gt;
  &lt;el-link disabled&gt;默认链接&lt;/el-link&gt;
  &lt;el-link type=&quot;primary&quot; disabled&gt;主要链接&lt;/el-link&gt;
  &lt;el-link type=&quot;success&quot; disabled&gt;成功链接&lt;/el-link&gt;
  &lt;el-link type=&quot;warning&quot; disabled&gt;警告链接&lt;/el-link&gt;
  &lt;el-link type=&quot;danger&quot; disabled&gt;危险链接&lt;/el-link&gt;
  &lt;el-link type=&quot;info&quot; disabled&gt;信息链接&lt;/el-link&gt;
&lt;/div&gt;
</code></pre></template></demo-block><h3>下划线</h3>
<p>文字链接下划线。</p>
<demo-block>
        
        <template #source><element-demo2 /></template>
        <template #highlight><pre v-pre><code class="html">&lt;div&gt;
  &lt;el-link :underline=&quot;false&quot;&gt;无下划线&lt;/el-link&gt;
  &lt;el-link&gt;有下划线&lt;/el-link&gt;
&lt;/div&gt;
</code></pre></template></demo-block><h3>图标</h3>
<p>带图标的文字链接可增强辨识度。</p>
<demo-block>
        
        <template #source><element-demo3 /></template>
        <template #highlight><pre v-pre><code class="html">&lt;div&gt;
  &lt;el-link icon=&quot;el-icon-edit&quot;&gt;编辑&lt;/el-link&gt;
  &lt;el-link&gt;查看&lt;i class=&quot;el-icon-view el-icon--right&quot;&gt;&lt;/i&gt; &lt;/el-link&gt;
&lt;/div&gt;
</code></pre></template></demo-block><h3>Attributes</h3>
<table>
<thead>
<tr>
<th>参数</th>
<th>说明</th>
<th>类型</th>
<th>可选值</th>
<th>默认值</th>
</tr>
</thead>
<tbody>
<tr>
<td>type</td>
<td>类型</td>
<td>string</td>
<td>primary / success / warning / danger / info</td>
<td>default</td>
</tr>
<tr>
<td>underline</td>
<td>是否下划线</td>
<td>boolean</td>
<td>—</td>
<td>true</td>
</tr>
<tr>
<td>disabled</td>
<td>是否禁用状态</td>
<td>boolean</td>
<td>—</td>
<td>false</td>
</tr>
<tr>
<td>href</td>
<td>原生 href 属性</td>
<td>string</td>
<td>—</td>
<td>-</td>
</tr>
<tr>
<td>icon</td>
<td>图标类名</td>
<td>string</td>
<td>—</td>
<td>-</td>
</tr>
</tbody>
</table>

      </section>
    </template>
    <script>
      import hljs from 'highlight.js'
      import * as Vue from "vue"
      export default {
        name: 'component-doc',
        components: {
          "element-demo0": (function() {
    
    const { createTextVNode: _createTextVNode, resolveComponent: _resolveComponent, withCtx: _withCtx, createVNode: _createVNode, openBlock: _openBlock, createBlock: _createBlock } = Vue

function render (_ctx, _cache) {
  const _component_el_link = _resolveComponent("el-link")

  return (_openBlock(), _createBlock("div", null, [
    _createVNode(_component_el_link, {
      href: "https://element3-ui.com",
      target: "_blank"
    }, {
      default: _withCtx(() => [
        _createTextVNode("默认链接")
      ]),
      _: 1
    }),
    _createVNode(_component_el_link, { type: "primary" }, {
      default: _withCtx(() => [
        _createTextVNode("主要链接")
      ]),
      _: 1
    }),
    _createVNode(_component_el_link, { type: "success" }, {
      default: _withCtx(() => [
        _createTextVNode("成功链接")
      ]),
      _: 1
    }),
    _createVNode(_component_el_link, { type: "warning" }, {
      default: _withCtx(() => [
        _createTextVNode("警告链接")
      ]),
      _: 1
    }),
    _createVNode(_component_el_link, { type: "danger" }, {
      default: _withCtx(() => [
        _createTextVNode("危险链接")
      ]),
      _: 1
    }),
    _createVNode(_component_el_link, { type: "info" }, {
      default: _withCtx(() => [
        _createTextVNode("信息链接")
      ]),
      _: 1
    })
  ]))
}
  
    const democomponentExport = {}
    return {
      mounted(){
        this.$nextTick(()=>{
          const blocks = document.querySelectorAll('pre code:not(.hljs)')
          Array.prototype.forEach.call(blocks, hljs.highlightBlock)
        })
      },
      render,
      ...democomponentExport
    }
  })(),"element-demo1": (function() {
    
    const { createTextVNode: _createTextVNode, resolveComponent: _resolveComponent, withCtx: _withCtx, createVNode: _createVNode, openBlock: _openBlock, createBlock: _createBlock } = Vue

function render (_ctx, _cache) {
  const _component_el_link = _resolveComponent("el-link")

  return (_openBlock(), _createBlock("div", null, [
    _createVNode(_component_el_link, { disabled: "" }, {
      default: _withCtx(() => [
        _createTextVNode("默认链接")
      ]),
      _: 1
    }),
    _createVNode(_component_el_link, {
      type: "primary",
      disabled: ""
    }, {
      default: _withCtx(() => [
        _createTextVNode("主要链接")
      ]),
      _: 1
    }),
    _createVNode(_component_el_link, {
      type: "success",
      disabled: ""
    }, {
      default: _withCtx(() => [
        _createTextVNode("成功链接")
      ]),
      _: 1
    }),
    _createVNode(_component_el_link, {
      type: "warning",
      disabled: ""
    }, {
      default: _withCtx(() => [
        _createTextVNode("警告链接")
      ]),
      _: 1
    }),
    _createVNode(_component_el_link, {
      type: "danger",
      disabled: ""
    }, {
      default: _withCtx(() => [
        _createTextVNode("危险链接")
      ]),
      _: 1
    }),
    _createVNode(_component_el_link, {
      type: "info",
      disabled: ""
    }, {
      default: _withCtx(() => [
        _createTextVNode("信息链接")
      ]),
      _: 1
    })
  ]))
}
  
    const democomponentExport = {}
    return {
      mounted(){
        this.$nextTick(()=>{
          const blocks = document.querySelectorAll('pre code:not(.hljs)')
          Array.prototype.forEach.call(blocks, hljs.highlightBlock)
        })
      },
      render,
      ...democomponentExport
    }
  })(),"element-demo2": (function() {
    
    const { createTextVNode: _createTextVNode, resolveComponent: _resolveComponent, withCtx: _withCtx, createVNode: _createVNode, openBlock: _openBlock, createBlock: _createBlock } = Vue

function render (_ctx, _cache) {
  const _component_el_link = _resolveComponent("el-link")

  return (_openBlock(), _createBlock("div", null, [
    _createVNode(_component_el_link, { underline: false }, {
      default: _withCtx(() => [
        _createTextVNode("无下划线")
      ]),
      _: 1
    }),
    _createVNode(_component_el_link, null, {
      default: _withCtx(() => [
        _createTextVNode("有下划线")
      ]),
      _: 1
    })
  ]))
}
  
    const democomponentExport = {}
    return {
      mounted(){
        this.$nextTick(()=>{
          const blocks = document.querySelectorAll('pre code:not(.hljs)')
          Array.prototype.forEach.call(blocks, hljs.highlightBlock)
        })
      },
      render,
      ...democomponentExport
    }
  })(),"element-demo3": (function() {
    
    const { createTextVNode: _createTextVNode, resolveComponent: _resolveComponent, withCtx: _withCtx, createVNode: _createVNode, openBlock: _openBlock, createBlock: _createBlock } = Vue

function render (_ctx, _cache) {
  const _component_el_link = _resolveComponent("el-link")

  return (_openBlock(), _createBlock("div", null, [
    _createVNode(_component_el_link, { icon: "el-icon-edit" }, {
      default: _withCtx(() => [
        _createTextVNode("编辑")
      ]),
      _: 1
    }),
    _createVNode(_component_el_link, null, {
      default: _withCtx(() => [
        _createTextVNode("查看"),
        _createVNode("i", { class: "el-icon-view el-icon--right" })
      ]),
      _: 1
    })
  ]))
}
  
    const democomponentExport = {}
    return {
      mounted(){
        this.$nextTick(()=>{
          const blocks = document.querySelectorAll('pre code:not(.hljs)')
          Array.prototype.forEach.call(blocks, hljs.highlightBlock)
        })
      },
      render,
      ...democomponentExport
    }
  })(),
        }
      }
    </script>
  